import { TrackingService } from '../tracking/TrackingService';
import { NoContentReason, TrackingNoContentPayload } from '../tracking/TrackingNoContentPayload';

export class TrackingNoContentService {
	constructor(
		private readonly trackingService: TrackingService
	) {
	}

	init() {
		const noContentReason = document.querySelector('.up-teaser-tracking')?.getAttribute(
			'data-tracking-teaser-no-content-reason') as NoContentReason | undefined;

		const trackingPayload = TrackingNoContentPayload.onNoTeaser(
			noContentReason);
		this.trackingService.sendMerge(trackingPayload);
	}
}
