export class TrackingNoContentPayload {
	constructor() {
	}

	static onNoTeaser(reason): TrackingPayloadNoContentData {
		const payload: TrackingPayloadNoContentData = { up_NoContent: true };

		if (reason) {
			payload.up_NoContentReason = reason;
		}

		return payload;
	}
}

export interface TrackingPayloadNoContentData {
	up_NoContent: Boolean,
	up_NoContentReason?: NoContentReason
}

export enum NoContentReason {
	NO_LOGIN = 'NoLogin',
	LOW_COVERAGE = 'LowCoverage',
	BLOCKED_CUSTOMER_TYPE = 'BlockedCustomerType',
	TRANSACTION_IN_PROGRESS = 'TransactionInProgress'
}
